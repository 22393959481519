<template>
  <v-container>
    <v-row justify="center" align="center" class="mt-5">
      <v-col cols="12">
        <v-card class="pa-5">
          <h1 class="text-h3 text-center app-green--text">
            {{ $t('store.thankYouForSupport') }}
            <v-img
              class="d-inline-flex mt-1"
              width="45"
              height="45"
              :src="confetti"
            ></v-img>
          </h1>
          <h1
            class="text-h5 text-center pt-4"
            v-html="$t('store.gemsPurchased', { value: gems })"
          ></h1>
          <div class="text-center mt-4">
            <v-btn dark color="app-blue" large to="/store">
              {{ $t('common.goToStore') }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'success-stripe-payment',
  computed: {
    confetti() {
      return require('../../assets/images/confetti.svg');
    },
    gems() {
      return this.$route.query.gems;
    }
  }
};
</script>
